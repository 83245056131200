// import { NextResponse } from "next/server";
import { flattenAttributes, strapiApiKey } from "./util";

const getRequest = async (url, options = {}) => {
  if (options && options?.lang) {
    // Determine if the URL already has query parameters
    const separator = url.includes("?") ? "&" : "?";
    // Append the lang parameter to the URL
    url += `${separator}locale=${options.lang}`;
  }

  options.headers = {
    Authorization: `Bearer ${strapiApiKey}`,
  };

  try {
    let response = await fetch(url, {
      ...options,
      next: { revalidate: 0 }, // For server-side rendering, ensure no cache
    });

    response = await response.json();
    return flattenAttributes(response);
  } catch (error) {
    throw error;
  }
};

export default getRequest;

export const makeApiRequest = async (
  url,
  method,
  body,
  tags,
  cache = false
) => {
  // const isDev = process.env.NODE_ENV === "development";

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${strapiApiKey}`,
      },
      body: body ? JSON.stringify(body) : null,
      next: { revalidate: 0 }, // For server-side rendering, ensure no cache
    });

    const data = await response.json();
    const flattenedResponse = flattenAttributes(data);

    if (!response.ok) {
      throw new Error(data?.error?.message || "Something went wrong");
    }

    return flattenedResponse;
  } catch (error) {
    return { error: error?.response?.message };
  }
};

export const getRequestNew = async (
  url,
  options = {},
  cache = false,
  revalidate = false
) => {
  if (options && options?.lang) {
    // Determine if the URL already has query parameters
    const separator = url.includes("?") ? "&" : "?";
    // Append the lang parameter to the URL
    url += `${separator}locale=${options.lang}`;
  }

  options.headers = {
    Authorization: `Bearer ${strapiApiKey}`,
  };

  try {
    let response = await fetch(url, {
      ...options,
      next: { revalidate: 0 }, // For server-side rendering, ensure no cache
    });

    response = await response.json();
    return flattenAttributes(response);
  } catch (error) {
    throw error;
  }
};

export const getRequestNewWithCacheOption = async (
  url,
  options = {},
  cache = false,
  revalidate = false,
  cacheValue
) => {
  if (options && options?.lang) {
    // Determine if the URL already has query parameters
    const separator = url.includes("?") ? "&" : "?";
    // Append the lang parameter to the URL
    url += `${separator}locale=${options.lang}`;
  }

  options.headers = {
    Authorization: `Bearer ${strapiApiKey}`,
  };

  try {
    let response = await fetch(url, {
      ...options,
      ...(cacheValue ? { cache: cacheValue } : {}),
      // next: cache && revalidate ? { revalidate: 3600 * 6 } : {},
    });

    response = await response.json();
    return flattenAttributes(response);
  } catch (error) {
    throw error;
  }
};

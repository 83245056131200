const data = {
  allCourses:
    "/api/courses?populate[category][populate]=true&pagination[limit]=2000",
  courses:
    "/api/courses?populate[thumbnail][populate]=true&populate[category][populate][localizations][populate]=true&populate[affiliations][populate][logo][populate]=true&populate[overview][populate]=true&populate[objective][populate]=true&populate[prerequisites][populate]=true&populate[brochure][populate]=true&populate[faqs][populate]=true&populate[sessions][populate][slots][populate]=true&populate[brands][populate][logo][populate]=true&populate[localizations]=true&filters[show_on_search][$eq]=true",
  affiliations: "/api/affiliations?populate[logo][populate]=true",
  singleCourse: "/api/courses",
  singleCourseNew:
    "/api/courses?populate[category][populate][select]=name,slug&populate[thumbnail][select]=name,formats,mime,url,provider&populate[affiliations][select]=name&populate[affiliations][populate][logo][select]=formats,mime,url,provider,name&populate[video_demo][select]=name,formats,mime,url,provider&populate[brochure][select]=name,mime,url,provider,formats&populate[overview][populate][details]=true&populate[objective][populate][details]=true&populate[prerequisites][populate][details]=true&populate[faqs]=true&populate[course_modules]=true&populate[certificates][select]=name,description&populate[certificates][populate][image][select]=formats,mime,url,provider,name&populate[trainers][select]=title,about&populate[trainers][populate][image][select]=formats,mime,url,provider,name&populate[about]=true&populate[localizations]=true",
  singleCouseMeta:
    "/api/courses?fields[0]=meta_title&fields[1]=meta_data&fields[2]=meta_keywords",
  sessionsOne: "/api/sessions?",
  singleCategory: "/api/categories",
  testimonials: "/api/testimonials?populate=*",
  testimonialsOne:
    "/api/testimonials?populate[image][select]=formats,mime,url,provider,name",
  categories: "/api/categories",
  home: "/api/home?populate[banner][populate][logo][populate]=true&populate[banner][populate][media][populate][attachment][populate]=true&populate[about][populate][attachment][populate]=true",
  contactPage:
    "/api/contact?populate[locations][populate][attachment]=true&populate[attachment][populate]=true&populate[placeholder][populate]=true",
  postContact: "/api/contact-requests",
  sessions: "/api/sessions?populate=*",
  trendingCourses:
    "/api/trending?populate[courses][populate][thumbnail][populate]=true&populate[courses][populate][category][populate]=true&populate[affiliations][populate][logo][populate]=true",
  getClients:
    "/api/our-client?populate[clients][populate][attachment][populate]=true",
  getWhyUs: "/api/why-us?populate=*",
  testimonials: "/api/testimonials?populate=*",
  aboutUs:
    "/api/about?populate[our_mission][pouplate]=true&populate[our_vision][populate]=true&populate[image][populate]=true&populate[our_values][populate][details][populate]=true&populate[our_achivements][populate][details][populate]=true&populate[our_peoples][populate][info][populate]=true&populate[our_peoples][populate][gallery][populate][attachment][populate]=true",
  getTraining:
    "/api/training?populate[banner_image][populate]=true&populate[about][populate][attachment][populate]=true&populate[case_study][populate][listing][populate][attachment][populate]=true",
  blogs: "/api/blogs?populate=*",
  getSingleBlog: "/api/blogs",
  login: "/api/auth/local",
  socialLogin: (provider) => `/api/auth/${provider}/callback`,
  register: "/api/auth/local/register",
  confirmEmail: "/api/auth/confirm-email",
  sendOrResendEmail: "/api/auth/send-email-confirmation",
  forgetPassword: "/api/auth/forgot-password",
  resetPassword: "/api/auth/reset-password",
  downloadBrochure: "/api/brochure-downloads",
  demoBooking: "/api/demo-bookings",
  headerMenus: "/api/header-menu?populate=*",
  footerMenus: "/api/footer-menu?populate=*",
  lpTalks: "/api/lp-talks",
  lpTalksRegistration: "/api/lp-talk-registrations",
  ezForms: "/api/ezforms/submit",
  currenyRates: "/api/currency-rates",
  teamUpskill: "/api/team-upskills",
  privacyPolicy: "/api/privacy-policy",
  brands: "/api/brands",
  globalRating: "/api/global-ratings?populate=*",
  relatedCourse:
    "/api/courses?populate[thumbnail][populate]=true&populate[category][populate]=true&populate[affiliations][populate][logo][populate]=true&populate[brochure][populate]=true&pagination[limit]=10&populate[localizations]=*",
};

export default data;

import dayjs from "dayjs";

import advancedFormat from "dayjs/plugin/advancedFormat";
import { i18n } from "../i18n-config";

dayjs.extend(advancedFormat);

export const baseUrl = process.env.NEXT_PUBLIC_SERVER_API;
export const nextPublicUrl = process.env.NEXT_PUBLIC_BASE_URL;

export const assetsCdnUrl =
  process.env.NEXT_PUBLIC_ASSETS_CDN_URL ||
  "https://d3v381xb6gm5e.cloudfront.net/media/";

export const paymob = {
  baseUrl: process.env.NEXT_PUBLIC_PAYMOB_URL,
  publicKey: process.env.NEXT_PUBLIC_PAYMOB_PUBLIC_KEY,
  authKey: process.env.NEXT_PUBLIC_PAYMOB_AUTH_KEY,
};

export const strapiApiKey = process.env.NEXT_PUBLIC_STRAPI_API_KEY;

export const bitrix = {
  leadUrl: process.env.NEXT_PUBLIC_BITRIX_LEAD_URL,
  leadAddUrl: process.env.NEXT_PUBLIC_BITRIX_LEAD_ADD_URL,
};

export const bitrixSourceIds = {
  downloadBoucher: 6,
  enquireNow: "UC_Z5SILG",
  contactUs: "WEB",
  bookDemo: 22,
  bespoke: 31,
  lpTalk: 41,
};

export const imageMimeTypes = [
  "image/jpeg", // JPEG/JPG images
  "image/png", // PNG images
  "image/gif", // GIF images
  "image/webp", // WebP images
  "image/bmp", // BMP images
  "image/avif", // AVIF images
  "image/tiff", // TIFF images
  "image/svg+xml", // SVG images
  "image/x-icon", // ICO images (favicon)
  "image/heif", // HEIF images (High Efficiency Image Format)
  "image/heic", // HEIC images (High Efficiency Image Coding)
  "image/vnd.microsoft.icon", // ICO alternative MIME type
  "image/jp2", // JPEG 2000 images
  "image/x-pict", // PICT images (Macintosh format)
  "image/x-portable-bitmap", // PBM images
  "image/x-xbitmap", // XBM images (X Windows bitmap)
  "image/x-tga", // TGA images (Targa)
];

export const months = [
  { key: "January", label: "Jan" },
  { key: "February", label: "Feb" },
  { key: "March", label: "Mar" },
  { key: "April", label: "Apr" },
  { key: "May", label: "May" },
  { key: "June", label: "Jun" },
  { key: "July", label: "Jul" },
  { key: "August", label: "Aug" },
  { key: "September", label: "Sep" },
  { key: "October", label: "Oct" },
  { key: "November", label: "Nov" },
  { key: "December", label: "Dec" },
];

export const sessionTypes = [
  { key: "online", label: "Online" },
  { key: "classroom", label: "Classroom" },
];

export const weekTypes = [
  { key: "weekend", label: "Weekend" },
  { key: "weekday", label: "Weekday" },
];

export const language = [
  { key: "English", label: "English" },
  { key: "Arabic", label: "Arabic" },
];
export const weekdays = [
  { key: "mon", label: "Mon" },
  { key: "tus", label: "Tus" },
  { key: "wed", label: "Wed" },
  { key: "Thu", label: "Thu" },
  { key: "Fri", label: "Fri" },
];
export function flattenAttributes(data) {
  // Check if data is a plain object; return as is if not
  if (
    typeof data !== "object" ||
    data === null ||
    data instanceof Date ||
    typeof data === "function"
  ) {
    return data;
  }

  // If data is an array, apply flattenAttributes to each element and return as array
  if (Array.isArray(data)) {
    return data.map((item) => flattenAttributes(item));
  }

  // Initialize an object with an index signature for the flattened structure
  let flattened = {};

  // Iterate over each key in the object
  for (let key in data) {
    // Skip inherited properties from the prototype chain
    if (!data.hasOwnProperty(key)) continue;

    // If the key is 'attributes' or 'data', and its value is an object, merge their contents
    if (
      (key === "attributes" || key === "data") &&
      typeof data[key] === "object" &&
      !Array.isArray(data[key])
    ) {
      Object.assign(flattened, flattenAttributes(data[key]));
    } else {
      // For other keys, copy the value, applying flattenAttributes if it's an object
      flattened[key] = flattenAttributes(data[key]);
    }
  }

  return flattened;
}

export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export function convertTimeToAMPM(timeString) {
  if (timeString) {
    const [hours, minutes, seconds] = timeString?.split(":");
    let hoursInt = parseInt(hours, 10);
    const period = hoursInt >= 12 ? "PM" : "AM";

    hoursInt = hoursInt % 12 || 12; // Convert '0' to '12' for midnight
    const formattedTime = `${hoursInt}:${minutes} ${period}`;

    return formattedTime;
  }
  return "";
}

export function convertTimeToAMPM2(utcTimeString) {
  if (utcTimeString) {
    const [date, time] = utcTimeString.split("T");

    // Parse the UTC time string into a Date object
    const [hours, minutes, seconds] = time.split(":");
    const utcDate = new Date();

    // Set the time based on the provided string (assume the date is today)
    utcDate.setUTCHours(hours, minutes, 0);

    // Convert to local time
    const localDate = new Date(
      utcDate.toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );

    // Get local hours, minutes, and determine AM/PM
    let localHours = localDate.getHours();
    const localMinutes = localDate.getMinutes().toString().padStart(2, "0");
    const period = localHours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    localHours = localHours % 12 || 12;

    // Format the time
    const formattedTime = `${localHours}:${localMinutes} ${period}`;

    return formattedTime;
  }
  return "";
}

export function convertTimeToAMPMForTime(utcTimeString) {
  if (utcTimeString) {
    const time = utcTimeString;

    // Parse the UTC time string into a Date object
    const [hours, minutes, seconds] = time.split(":");
    const utcDate = new Date();

    // Set the time based on the provided string (assume the date is today)
    utcDate.setUTCHours(hours, minutes, 0);

    // Convert to local time
    const localDate = new Date(
      utcDate.toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );

    // Get local hours, minutes, and determine AM/PM
    let localHours = localDate.getHours();
    const localMinutes = localDate.getMinutes().toString().padStart(2, "0");
    const period = localHours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    localHours = localHours % 12 || 12;

    // Format the time
    const formattedTime = `${localHours}:${localMinutes} ${period}`;

    return formattedTime;
  }
  return "";
}
// Utility method to format date as "12 March"
export function formatDateWithMonth(date) {
  return dayjs(date).format("Do MMMM");
}

// Utility method to format date as "12 March, Monday"
export function formatDateWithDay(date) {
  return dayjs(date).format("Do MMMM, dddd");
}

/**
 * Get the start and end dates of the current month.
 * @returns {object} An object containing the start and end dates of the current month in YYYY-MM-DD format.
 */
export function getCurrentMonthStartEndDate() {
  const currentMonthStart = dayjs().format("YYYY-MM-DD");
  const currentMonthEnd = dayjs().endOf("month").format("YYYY-MM-DD");
  return {
    start: currentMonthStart,
    end: currentMonthEnd,
  };
}

/**
 * Get the start and end dates of the next month.
 * @returns {object} An object containing the start and end dates of the next month in YYYY-MM-DD format.
 */
export function getNextMonthStartEndDate() {
  const nextMonthStart = dayjs()
    .add(1, "month")
    .startOf("month")
    .format("YYYY-MM-DD");
  const nextMonthEnd = dayjs()
    .add(1, "month")
    .endOf("month")
    .format("YYYY-MM-DD");
  return {
    start: nextMonthStart,
    end: nextMonthEnd,
  };
}

/**
 * Get the start and end dates of the upcoming weekend.
 * @returns {object} An object containing the start and end dates of the upcoming weekend in YYYY-MM-DD format.
 */
export function getUpcomingWeekendStartEndDate() {
  const today = dayjs();
  const dayOfWeek = today.day();
  let weekendStart, weekendEnd;

  // If today is Friday, Saturday or Sunday, the upcoming weekend starts this week
  if (dayOfWeek >= 5) {
    weekendStart = today.day(5).format("YYYY-MM-DD"); // Friday
    weekendEnd = today.day(7).format("YYYY-MM-DD"); // Sunday
  } else {
    // Otherwise, it starts next Friday
    weekendStart = today.day(5 + 7).format("YYYY-MM-DD"); // Next Friday
    weekendEnd = today.day(7 + 7).format("YYYY-MM-DD"); // Next Sunday
  }

  return {
    start: weekendStart,
    end: weekendEnd,
  };
}

export function getCurrentWeekWeekdays() {
  const today = dayjs();
  const dayOfWeek = today.day();
  let weekdayStart, weekdayEnd;

  // If today is a weekday (Monday to Friday), get the current week's weekdays
  if (dayOfWeek >= 1 && dayOfWeek <= 5) {
    weekdayStart = today.day(1).format("YYYY-MM-DD"); // Monday
    weekdayEnd = today.day(5).format("YYYY-MM-DD"); // Friday
  } else {
    // Otherwise, get the next week's weekdays
    weekdayStart = today.day(1 + 7).format("YYYY-MM-DD"); // Next Monday
    weekdayEnd = today.day(5 + 7).format("YYYY-MM-DD"); // Next Friday
  }

  return {
    start: weekdayStart,
    end: weekdayEnd,
  };
}

export function splitArrayIntoFour(arr) {
  const result = [[], [], [], []];
  const minSize = 5;

  // If the array has fewer than 5 elements, put all elements in the first subarray
  if (arr.length < minSize) {
    result[0] = arr.slice();
    return result;
  }

  let index = 0;

  // Fill the first 4 subarrays with at least minSize elements
  for (let i = 0; i < 4; i++) {
    result[i] = arr.slice(index, index + minSize);
    index += minSize;
  }

  // Distribute the remaining elements evenly
  for (let i = 0; index < arr.length; i = (i + 1) % 4) {
    result[i].push(arr[index]);
    index++;
  }

  return result;
}

export function buildPaginationQuery(searchParams) {
  const defaultLimit = 6;
  const defaultStart = 0;

  let url = "";

  if (searchParams?.limit) {
    url += `&pagination[limit]=${searchParams.limit}`;
  } else {
    url += `&pagination[limit]=${defaultLimit}`;
  }

  if (searchParams?.start) {
    url += `&pagination[start]=${searchParams.start}`;
  } else {
    url += `&pagination[start]=${defaultStart}`;
  }

  return url;
}

export const getImageUrl = (image) => {
  if (image?.url) {
    return image?.provider === "aws-s3" ? image?.url : baseUrl + image?.url;
  } else {
    return "";
  }
};

export const getImageUrlNew = (image, size = "medium") => {
  if (image?.url) {
    return image?.provider === "aws-s3"
      ? image?.formats?.[size]
        ? image?.formats?.[size]?.url
        : image.url
      : baseUrl + image?.url;
  } else {
    return "";
  }
};

export const getLink = (url) => {
  // Extract the first segment from the URL by splitting on "/"

  const segments = url.split("/").filter(Boolean); // Filter to remove empty strings
  const firstSegment = segments[0]; // Get the first segment (locale)

  // Check if the first segment is the default locale
  if (firstSegment === i18n.defaultLocale) {
    // Remove the first segment (locale) and reconstruct the URL
    segments.shift(); // Remove the first segment

    return `/${segments.join("/")}`; // Reconstruct the URL without the default locale
  }

  // If the locale is not the default, return the original URL
  return url;
};

export function getMonthStartAndEndDates(monthName) {
  // Parse the month name to a month index (0-based, where January is 0)
  const index = months.findIndex((month) => month.key === monthName);

  const monthIndex = dayjs().month(index).month();

  // Get the current year
  const year = dayjs().year();

  // Create the date object for the first day of the given month
  const startOfMonth = dayjs().year(year).month(monthIndex).startOf("month");

  // Create the date object for the last day of the given month
  const endOfMonth = dayjs().year(year).month(monthIndex).endOf("month");

  return {
    created_at_gte: startOfMonth.format("YYYY-MM-DD"),
    created_at_lte: endOfMonth.format("YYYY-MM-DD"),
  };
}

export function convertTime(timeString) {
  // Parse the time string by splitting it into hours and minutes
  const [hours, minutes] = timeString.split(":");

  // Create a new dayjs object, setting the time to the given hours and minutes
  const parsedTime = dayjs()
    .hour(parseInt(hours, 10))
    .minute(parseInt(minutes, 10))
    .second(0)
    .millisecond(0);

  // Format the time to 'HH:mm:ss.SSS'
  const formattedTime = parsedTime.format("HH:mm:ss.SSS");

  return formattedTime;
}

export const isCompleteUrl = (url) => {
  // Regex to check if the URL starts with "http://" or "https://"
  const isCompleteUrl = /^(http|https):\/\//.test(url);
  return isCompleteUrl;
};
